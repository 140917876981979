<template>
  <v-container
    class="fast-search-menu"
    style="background-image: url('/fast-search-bg.svg')"
  >
    <v-layout fill-height column>
      <h3
        class="text-h2 font-weight-bold mx-auto my-2 text-center"
        v-html="title"
      ></h3>
      <div
        class="text-center"
        v-if="description"
        v-html="description"
        @click="clicked"
      ></div>
      <div class="form-container d-flex justify-center">
        <v-form
          @submit.prevent.stop="addProduct"
          class="d-flex flex-column justify-center"
        >
          <v-text-field
            v-model="searchString"
            dense
            :label="$t('fastSearch.fastSearchMenu.searchPlaceholder')"
            @keyup.enter.stop.prevent="addProduct"
            @paste="pasteText"
          >
            <template slot="append">
              <v-icon @click="addProduct">$plus</v-icon>
            </template>
          </v-text-field>
          <v-list class="pt-0 product-list">
            <v-list-item v-for="product in productList" :key="product">
              <v-list-item-title>{{ product }}</v-list-item-title>
              <v-list-item-action>
                <v-icon
                  @click="removeFastSearchWord(product)"
                  :aria-label="$t('common.close')"
                  color="default"
                  >$close</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <div class="d-flex flex-column align-center justify-center">
            <v-btn
              depressed
              color="primary"
              large
              class="my-3"
              @click="fastSearch"
            >
              {{ $t("fastSearch.fastSearchMenu.searchBtn") }}
            </v-btn>
            <v-btn
              depressed
              color="primary"
              large
              text
              class="my-3"
              @click="removeAllFastSearchWords"
            >
              {{ $t("fastSearch.fastSearchMenu.clearSearches") }}
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-layout>
  </v-container>
</template>
<style lang="scss">
.fast-search-menu {
  background-position: right bottom; /*Positioning*/
  background-repeat: no-repeat; /*Prevent showing multiple background images*/
  @media #{map-get($display-breakpoints, 'xs-only')} {
    background-image: none !important;
  }
  .form-container {
    .v-form {
      width: 70%;
    }
    .v-btn.search {
      width: 100%;
      max-width: 100%;
    }
    .v-input {
      .v-icon {
        padding: 4px;
        font-size: 20px;
        font-weight: bold;
        color: white !important;
        border-radius: 50%;
        background-color: var(--v-primary-base);
      }
    }
    .theme--light.v-list {
      background: transparent;
    }
    .v-list {
      .v-list-item {
        border-bottom: 1px solid var(--v-grey-lighten1);
      }
      .v-icon {
        font-size: 20px;
        font-weight: bold;
        color: var(--v-primary-base);
      }
    }
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import CategoryService from "~/service/categoryService";
import { mapActions, mapState } from "vuex";
import get from "lodash/get";

export default {
  name: "FastSearchMenu",
  data() {
    return {
      searchString: null,
      category: {}
    };
  },
  mixins: [clickHandler],

  computed: {
    ...mapState({
      productList: state => state.wordsSearched.fastSearchList
    }),
    getItems() {
      return this.search ? this.items : this.commonSearch;
    },
    title() {
      return get(
        this.category,
        "metaData.category_info.TITLE",
        this.category.name
      );
    },
    description() {
      return get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.description
      );
    }
  },
  methods: {
    ...mapActions({
      addFastSearchWord: "wordsSearched/addFastSearchWord",
      removeFastSearchWord: "wordsSearched/removeFastSearchWord",
      removeAllFastSearchWords: "wordsSearched/removeAllFastSearchWords"
    }),
    fastSearch() {
      this.$router.push({
        name: "FastSearch",
        query: { q: this.productList }
      });
      this.removeAllFastSearchWords();
      this.$emit("submit", true);
    },
    clearSearches() {},
    pasteText: function(event) {
      const paste = (event.clipboardData || window.clipboardData).getData(
        "text"
      );
      this.searchString = paste.split(/\s+/).join(",");

      event.preventDefault();
    },
    addProduct() {
      if (this.searchString) {
        const searchTerms = this.searchString.split(",").map(s => s.trim());
        for (const searchTerm of searchTerms) {
          this.addFastSearchWord(searchTerm);
        }
        // this.productList.push(this.searchString);
        this.searchString = null;
      }
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug(
          "fast-search-popup"
        );
      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted() {
    this.fetchCategory();
  }
};
</script>
