<template>
  <div class="autocomplete d-flex flex-row">
    <v-combobox
      class="search-input"
      clearable
      filled
      solo
      dense
      v-model="selected"
      ref="autocomplete"
      :items="getItems"
      :search-input.sync="search"
      no-filter
      :placeholder="$t('navbar.searchPlaceholder')"
      flat
      item-text="name"
      item-value="name"
      hide-details
      hide-no-data
      return-object
      type="search"
      @focus="onFocus"
      @blur="onBlur"
      @keydown.enter.native.prevent.stop="doSearch"
      :menu-props="{
        closeOnContentClick: true,
        contentClass: 'searches-menu mt-4'
      }"
    >
      <template v-slot:prepend-item>
        <category-block
          v-if="search && searchCategory"
          :target="searchCategory"
          position="positionmenu1"
          class="category-block-menu category-block-menu-header"
        />
      </template>
      <template v-slot:item="{ item }">
        <div class="d-flex w-100">
          <v-list-item-icon v-if="item.type == 'Ricerche'">
            <v-icon
              :aria-label="$t('common.close')"
              :title="$t('common.close')"
              @click.prevent.stop="deleteWord(item)"
              small
            >
              $close
            </v-icon>
          </v-list-item-icon>
          <v-list-item-icon v-else-if="item.type == 'FastSearch'">
            <v-icon color="primary" small>$info</v-icon>
          </v-list-item-icon>
          <template v-if="item.productId">
            <ProductSearchRow
              :product="item"
              @goToProductDetail="goToProductDetail"
            />
          </template>
          <v-list-item-content
            v-else
            @click="
              item.type == 'FastSearch'
                ? openFastSearchDialog()
                : doSearch(item)
            "
          >
            <v-list-item-title>
              <span
                class="font-weight-bold primary--text text-body-2"
                v-if="item.type == 'FastSearch'"
              >
                {{ $t("fastSearch.searchInput.label") }}
              </span>
              <text-highlight
                v-else
                :queries="search ? search : '*'"
                :class="{ 'font-weight-bold': item.type == 'link' }"
              >
                {{ item.name }}
              </text-highlight>
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.type == 'Categorie'">
              <v-badge
                inline
                v-if="item.count"
                :content="item.count"
                color="secondary"
              >
                Cerca <strong>{{ search }}</strong> in {{ item.name }}
              </v-badge>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.type == 'Marche'">
              Cerca <strong>{{ search }}</strong> con marca {{ item.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </div>
      </template>
      <template v-slot:append-item>
        <category-block
          v-if="search && searchCategory"
          :target="searchCategory"
          position="positionmenu2"
          class="category-block-menu category-block-menu-footer"
        />
      </template>
      <template slot="append">
        <v-icon
          aria-label="Cerca"
          @click.prevent.stop="doSearch"
          color="default"
          >$search</v-icon
        >
      </template>
    </v-combobox>
    <Barcode v-if="isCordova" @input="doSearch" />
  </div>
</template>
<style lang="scss">
.is-cordova {
  .search-input {
    border: none !important;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .autocomplete {
    max-width: 100% !important;
  }
  .search-input {
    .v-input__control {
      height: 45px !important;
      .v-input__slot {
        height: 45px !important;
      }
    }
  }
}
// @media #{map-get($display-breakpoints, 'sm-and-up')} {
//   .category-block-menu {
//     position: absolute;
//     width: 50%;
//     right: 0px;
//     top: 85px;
//     z-index: 2;
//   }
// }

.autocomplete {
  width: 100%;
  max-width: 500px;
}
.search-input {
  border: 1px solid var(--v-primary-lighten2);
  // border-radius: 4px;
  max-width: 926px;
  .v-input__control {
    display: inline;
    height: 60px;
    .v-input__slot {
      height: 60px;
      background-color: var(--v-grey-lighten1) !important;
    }
  }
}
.searches-menu {
  &.v-menu__content {
    max-height: 80vh !important;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      min-width: 330px !important;
    }
  }
  .v-list-item {
    .v-list-item__content {
      padding: 6px 0;
    }
    .v-list-item__title {
      font-size: 0.875rem;
    }
  }
  .v-subheader {
    font: normal normal bold 14px/44px Montserrat;
    white-space: nowrap;
    background-color: var(--v-grey-lighten1);
    padding: 0 16px;
  }
  .text__highlight {
    background: transparent;
    font-weight: bold;
  }
}
.is-cordova {
  .search-input {
    .v-input__control {
      .v-input__slot {
        .v-select__slot {
          @media #{map-get($display-breakpoints, 'xs-only')} {
            input {
              font-size: 14px;
            }
            .icon-lente {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
<script>
import Barcode from "./Barcode";

import FastSearchMenu from "./FastSearchMenu.vue";
import ProductSearchRow from "@/components/product/ProductSearchRow.vue";

import ProductService from "~/service/productService";
import AnalyticsService from "~/service/analyticsService";
import CategoryService from "~/service/categoryService";
import cmService from "@/commons/service/mksCmService";

import { mapGetters, mapActions } from "vuex";

import debounce from "lodash/debounce";
import forEach from "lodash/forEach";

export default {
  components: {
    ProductSearchRow,
    Barcode
  },
  data() {
    return {
      loading: false,
      isFocused: null,
      selected: null,
      firstSuggest: null,
      search: null,
      searchCategory: null,
      isListening: false,
      items: [],
      menuProps: {
        closeOnContentClick: true,
        openOnClick: true
      }
    };
  },
  watch: {
    search(val, oldVal) {
      if (val !== oldVal && val && val.length > 3) {
        this.fetchSuggestion();
      }
    }
  },
  computed: {
    ...mapGetters({
      commonSearch: "wordsSearched/commonSearch"
    }),
    getItems() {
      let result = this.search ? this.items : this.commonSearch;
      result.unshift({
        type: "FastSearch",
        name: "Spesa veloce"
      });
      return result;
    }
  },
  methods: {
    ...mapActions({
      saveWord: "wordsSearched/saveWord",
      deleteWord: "wordsSearched/deleteWord"
    }),
    clearSearchBar() {
      let _this = this;
      setTimeout(function() {
        _this.selected = "";
      }, 80);
    },
    getImage(item) {
      return "/img_layout/categories_icon/" + item.id + ".svg";
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    fetchSuggestion: debounce(async function() {
      let _this = this;
      _this.loading = true;
      if (_this.search) {
        let newItems = [];
        _this.firstSuggest = null;
        let response = await ProductService.suggest(_this.search.trim());
        let tempItems = [];
        if (response.length > 0) {
          let prevType = "none";
          let suggestCount = 0;
          let categoryCount = 0;

          forEach(response, function(value) {
            if (value.type != prevType) {
              tempItems.push({ header: value.type });
              prevType = value.type;
            }

            if (value.type == "Suggerimenti" && suggestCount < 3) {
              if (!_this.firstSuggest) _this.firstSuggest = value.name;
              tempItems.push(value);
              suggestCount++;
            }
            if (value.type == "Categorie" && categoryCount < 1) {
              tempItems.push(value);
              categoryCount++;
            }
          });
        }

        // load category search to show proposal in menu
        _this.searchCategory = await CategoryService.getCategoryBySlug(
          "search",
          false,
          _this.search
        );
        // load first 3 products to show a preview in menu

        if (_this.firstSuggest) {
          let res = await ProductService.search({
            q: _this.firstSuggest,
            page: 1,
            page_size: 3
          });
          if (res.products) {
            newItems.push({ header: "Prodotti" });
            forEach(res.products, function(value) {
              newItems.push(value);
            });
          }
        }
        newItems = [...newItems, ...tempItems];
        // load post and recupe from wp
        cmService
          .searchArticle({
            q: this.search.trim(),
            parent_article_type_id: [3, 5, 6],
            page_size: 4
          })
          .then(function(response) {
            if (response.data.articles.length > 0) {
              newItems.push({ header: "News e ricette" });
              forEach(response.data.articles, function(value) {
                newItems.push({
                  name: value.title,
                  type: "News e ricette",
                  ...value
                });
              });
              newItems.push({
                type: "link",
                name: "Cerca tra news e ricette >"
              });
            }
          });

        _this.loading = false;
        _this.items = newItems;
      }
    }, 200),
    doSearch(item) {
      this.items = [];

      var currentQuery = {};
      if (item.type === "Suggerimenti") {
        //ho selezionato un nome
        currentQuery.q = item.name;
      } else if (item.type === "Categorie") {
        currentQuery.parent_category_id = parseInt(item.id);
        currentQuery.filter = item.name;
        currentQuery.q = this.firstSuggest;
      } else if (item.type === "Ricerche") {
        currentQuery.q = item.name;
      } else if (item.type === "Marche") {
        currentQuery.parent_vendor_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "Barcode") {
        currentQuery.barcode = item.name;
      } else if (item.type === "FastSearch") {
        currentQuery.q = "";
        this.clearSearchBar();
      } else if (item.type === "News e ricette") {
        currentQuery.q = this.search;
      } else if (item.type === "link") {
        currentQuery.q = this.search;
      } else if (this.search != null) {
        currentQuery.q = this.search;
        this.saveWord(currentQuery.q);
      }

      if (currentQuery.q || currentQuery.barcode) {
        AnalyticsService.search(this.search);
        //this.$store.dispatch("category/resetFilters");
        global.EventBus.$emit("resetFilters");
        if (item.type == "News e ricette") {
          this.$router.push({
            name: item.articleType.name,
            params: { pageName: item.slug }
          });
        } else if (item.type == "link") {
          this.$router.push({
            name: "CmSearch",
            query: currentQuery
          });
        } else {
          this.$router.push({
            path: "/search",
            name: "Search",
            query: currentQuery
          });
        }

        this.$nextTick(() => {
          this.$refs.autocomplete.blur();
          this.search = currentQuery.q;
        });
      }
    },
    handleHeaderHeight() {
      const headerContainer = this?.$refs?.autocomplete?.$refs.menu;
      if (headerContainer) {
        headerContainer.isActive = false;
        this.$nextTick(() => {
          const topVal = window.getComputedStyle(
            document.querySelector(".v-main")
          ).paddingTop;
          this.$refs.autocomplete.$refs.menu.$el.style.top = `${topVal} !important`;
        });
      }
    },
    goToProductDetail(product) {
      this.$router.push({ name: "Product", params: { slug: product.slug } });
    },
    openFastSearchDialog() {
      this.$emit("closeSearchModal", true);
      this.clearSearchBar();
      var defaultConfig = {
        toolbarColor: "secondary",
        fullscreen: this.$vuetify.breakpoint.smAndDown
      };
      let properties = {
        waitForResult: true,
        width: 650
      };
      var config = Object.assign({}, properties, defaultConfig);
      this.dialogInstance = this.$dialog.show(FastSearchMenu, config);
    }
  },
  mounted() {
    global.EventBus.$on("clearSearch", () => {
      this.search = null;
      this.selected = null;
    });
    this.handleHeaderHeight();

    window.addEventListener("scroll", this.handleHeaderHeight);
  },
  beforeDestroy() {
    global.EventBus.$off("clearSearch");
  }
};
</script>
